<template>
 <div class="goodDetail">
   <div v-if="isData">
      <div class="detail-head">
        <div class="bg">
          <van-icon name="arrow-left" @click="back"/>
          <img class="courseImg" :src="detailObj.picUrl"/>
          <span v-if="detailObj.taskType" class="cType">{{type}}</span>
        </div>
          <div class="detail-box">
                  <div class="detail-title">
                      {{detailObj.name}}
                      <img src="../../../assets/images/courseGoods/cert-icon.png" v-if="detailObj && detailObj.hasCert" class="icon" alt="">
                  </div>
                  <ul class="tag-list-detail">
                      <li class="tag-item tag-active "
                        v-for="(tag, index) in detailObj.labelNameList"
                        :key="index"
                      >
                          <div class="text-tag">{{tag}}</div>
                      </li>
<!-- 
                      <li class="tag-item tag-active hide">你说呢,这是一个很长很长很长很长的标签呀</li>
                      <li class="tag-item tag-active hide">你说呢,这是一个很长很长很长很长的标签呀</li>
                      <li class="tag-item tag-active hide">你说呢,这是一个很长很长很长很长的标签呀</li>
                      <li class="tag-item tag-active hide">你说呢,这是一个很长很长很长很长的标签呀</li> -->
                  </ul>
                  <div class="price-box" v-if="!detailObj.hidePriceTag">
                      <span class="price">{{!detailObj.isFree?'￥'+detailObj.salePrice:'免费'}}</span><span class="or-price" v-if="!detailObj.isFree&&detailObj.basePrice">￥{{detailObj.basePrice}}</span>
                  </div>
                  <div class="detail-price">
                      <span class="detail-lasttime"><span class="detail-lasttime label-color" >有效期：</span>{{time}}</span>
                      <span v-if="isJoinPer=='true'" class="persons">{{detailObj.purchaseNo}}人已报名</span>
                  </div>
          </div>
      </div>
      <div class="detail-content">
          <van-tabs v-model="active" @click="onChange">
              <van-tab title="简介">
                <!-- <img class="courseImg" :src="detailObj.imgUrl"/> -->
                <div v-html="detailObj.desc" class="introCode"></div>
                </van-tab>
              <van-tab title="目录" v-if="isTab=='true'&&detailObj.goodsType!=3">
                  <div class="list">
                      <courseItem :itemList="catalogList" :num="0" :isFirst="isFirst" :goodsId="goodsId" :sort="1" :isOverType="isOverType" :detailObj="detailObj"></courseItem>
                  </div>
              </van-tab>
          </van-tabs>
      </div>
      <div class="myTorst" v-show="showT">
        <img src="../../../assets/images/courseGoods/bingo.png" class="bingo"/>
        <div>已成功加入</div>
      </div>
      <div class="detail-foot">
          <button @click="enroll" v-if="isShowNowSignUp && detailObj.status==1&&!detailObj.isOrder">立即报名</button>
          <div v-else-if="detailObj.status==1&&detailObj.isOrder">
            <!-- 测评 -->
            <template v-if="detailObj.goodsType == 3">
              <button class="rightBtn" @click="isToStudy" :class="isOverType=='oks'?'':'downClass'">{{isOver}}</button>
            </template>
            <!-- 培训任务、训练营 -->
            <!-- <template v-else-if="canToQuicklyStudy"> -->
              <!-- <button class="leftBtn" @click="isToStudy" :class="isOverType=='oks'?'':'downClass'">任务大纲</button> -->
              <!-- <button class="rightBtn" @click="quicklyStudy" :class="isOverType=='oks'?'':'downClass'">{{isOver}}</button>
            </template>
            <template v-else> -->
              <button @click="isToStudy" :class="isOverType=='oks'?'':'downClass'">{{isOver}}</button>
            <!-- </template> -->
          </div>
          <!-- <button @click="isToStudy" :class="isOverType=='oks'?'':'downClass'" v-else-if="detailObj.status==1&&detailObj.isOrder">{{isOver}}</button> -->
          <button v-else-if="isShowNowSignUp" style="background: #E2E2E2;" class="downClass">{{statusText}}</button>
      </div>
   </div>
    <div v-else class="loadingBox">
      <van-loading type="spinner" color="#1989fa" />
      <div>加载中...</div>
    </div>
 </div>
</template>

<script>
import courseItem from 'wdn-h5/src/modules/courseGoods/page/courseItem.vue';
import { Toast } from 'vant';
export default {
    name: "opcourseGoodsDetail",
    components: {
        courseItem
    },
    data () {
        return {
            title:'商品详情',
            showT:false,
            goodsId:parseInt(this.$route.query.goodsId),//商品ID
            isData:false,
            detailObj:{
              basePrice: 0,//划线价格
              days: 0,//购买多少天有效
              desc: "",//简介
              endTime: "",
              goodsType: 1,//商品引用类型1.训练营 2.培训任务
              id: 0,
              isFree: 1,//是否免费
              isOrder: 0,//是否已经购买 0 否 1 是
              name: "",//商品名称
              picUrl: "",
              purchaseNo: 0,//报名人数
              referId: 0,//商品引用ID
              salePrice: 0,//销售价格
              startTime: "",
              status: 0,//状态 0下架 1上架 2 待上架
              taskType: 0,//任务类型 1.必修课 2.选修课 3.公开课
              tryWatch: 0,//商品有效期类型（1:永久有效、2:期间、3:固定时间、4:购买后期间）
              tryWatchList: [//可试看列表
                {
                  itemId: 0,
                  value: 0
                }
              ],
              validityType: 1//有效期类型
            },
          active:0,
          catalogList:[//目录列表
          ],
          isFirst:true,//是否一级
          isTab:'true',
          isJoinPer:'true',
          isShowNowSignUp: true,
          lastStudyCourseId:'',
          lastPlayItemId:'',
          taskId:'',
          canToQuicklyStudy:false,
          firstStudy:true,// 是否已经学过了
        };
    },
    computed: {
      //课程类型
      type(){
        if(this.detailObj.taskType==1){
          return '必修课'
        }else if(this.detailObj.taskType==2){
          return '选修课'
        }else{
          return '公开课'
        }
      },
        //上架状态
      statusText(){
        if(this.$route.query.goodsKind == 1){
            if(this.detailObj.status==1){
                  return '上架'
              }else if(this.detailObj.status==0){
                  return '下架'
              }else {
                  return '待上架'
              }
        }else {
              if(this.detailObj.status==1){
                  return '上架'
              }else if(this.detailObj.status==2){
                  return '下架'
              }else {
                   return '待上架'
              }
        }
      },
      
      //是否过期文案显示
    isOver(){
      if(this.detailObj.endTime&&this.detailObj.validityType==4){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>nowTime){
          return this.firstStudy ?'立即学习':'继续学习'
        }else{
          return '已过期'
        }
      }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>=nowTime&&startTime<=nowTime){
          return this.firstStudy ?'立即学习':'继续学习'
        }else{
          if(startTime>nowTime){
            return '未开始'
          }else{
            return '已过期'
          }
        }
      }else{
        return this.firstStudy ?'立即学习':'继续学习'
      }
    },
    //是否过期
    isOverType(){
      if(this.detailObj.isOrder){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();  
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            return 'oks'
          }else{
            return 'no2'
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
            return 'oks'
          }else{
            if(startTime>=nowTime){
              return 'no1'
            }else{
              return 'no2'
            }
          }
        }else if(this.detailObj.validityType==1){
          return 'oks'
        }else{
          return 'ok'
        }
      }else{
          return 'ok'
      }
    },
      //有效期
      time(){
        if(this.detailObj.validityType==1){
          return "永久有效"
        }else if(this.detailObj.validityType==2){
          let sarr = this.detailObj.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else if(this.detailObj.validityType==3){
           if(this.detailObj.startTime&&this.detailObj.endTime){
              let sarr = this.detailObj.startTime.split(' ');
              let stimeArr = sarr[0].split('-');
              stimeArr[0] = stimeArr[0]+'年';
              stimeArr[1] = stimeArr[1]+'月';
              stimeArr[2] = stimeArr[2]+'日';
              let snewTime = stimeArr.join('');
              let earr = this.detailObj.endTime.split(' ');
              let etimeArr = earr[0].split('-');
              etimeArr[0] = etimeArr[0]+'年';
              etimeArr[1] = etimeArr[1]+'月';
              etimeArr[2] = etimeArr[2]+'日';
              let enewTime = etimeArr.join('');
              return snewTime+"-"+enewTime
           }else{
              let earr = this.detailObj.endTime.split(' ');
              let etimeArr = earr[0].split('-');
              etimeArr[0] = etimeArr[0]+'年';
              etimeArr[1] = etimeArr[1]+'月';
              etimeArr[2] = etimeArr[2]+'日';
              let enewTime = etimeArr.join('');
              return "至"+enewTime
           }
        }else{
          return "报名后"+this.detailObj.days+"天内有效"
        }
      }
    },


    created(){
      sessionStorage.setItem('search',window.location.search)
      this.active = sessionStorage.getItem('theme') === 'minzheng'? 1:0
      let agentIdStorage = localStorage.getItem('detailAgentId')
      localStorage.setItem('detailAgentId', this.$route.query.agentId || agentIdStorage || '')
      if (this.$route.query.form && this.$route.query.form == 'promotionCenter' ) {
        console.log('隐藏立即报名')
        this.isShowNowSignUp = false
      }
      

      var frontendConfig = this.$store.state.dynamic.frontendConfig || [];
      var isTab = frontendConfig.filter((item)=>{
        return item.key == 'is_show_catalog_tab';
      })
      this.isTab = isTab[0].value;
      var isJoinPer = frontendConfig.filter((item)=>{
        return item.key == 'is_show_commodity_number_buyer_detail_page';
      })
      this.isJoinPer = isJoinPer[0].value;
    },

    async mounted(){
      if(this.$route.query.goodsKind == 2){
        await this.getDistributionGoodsInfo();// 分销商品详情

      }else {
        await this.getGoodsDetail();// 商品详情

      }
       this.getQuicklyStudyInfo();
       if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.back, false); //false阻止默认事件
        }
    },
    destroyed(){
        window.removeEventListener("popstate", this.back, false); //false阻止默认事件
        // localStorage.setItem('detailAgentId', this.$route.query.agentId || '')
    },

    methods: {
      
      getQuicklyStudyInfo(){
        if(this.detailObj.goodsType ==  3) return
        // 2培训任务 1训练营
        const taskType = this.detailObj.goodsType
        this.$api.learning.quicklyStudy(this.detailObj.referId,taskType).then( res => {
          if(res.code == 0 && res.data){
            this.canToQuicklyStudy = true
            this.lastStudyCourseId = res.data.courseId
            this.taskId = res.data.taskId
            this.lastPlayItemId = res.data.lastPlayItemId
            this.firstStudy = res.data.firstStudy
          }
        })
      },
      onChange(){
        if(document.getElementsByTagName('video').length>0){
          var video = document.getElementsByTagName('video');
          for(let i=0;i<video.length;i++){
            video[i].pause()
          }
        }
        if(document.getElementsByTagName('audio').length>0){
          var audio = document.getElementsByTagName('audio');
          for(let j=0;j<audio.length;j++){
            audio[0].pause()
          }
        }
      },
      //返回
      back(){
        // console.log(this.$store.state.isOpen)
        if(!this.$store.state.isOpen){
          this.$router.push(this.$route.query.backName?this.$route.query.backName:'/openPlatform/courseGoods');
        }else{
          this.$store.commit('setIsOpen',false);
          if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.back, false); //false阻止默认事件
          }
        }
      },
      // 目录大纲
      isToStudy(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            this.toStudy()
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
              this.toStudy()
          }
        }else{
          this.toStudy()
        }
        
      },
      toStudy(){
        if(this.detailObj.goodsType==1){
          this.$router.push({
            path:'/learning/Details',
            query:{
              id:this.detailObj.referId,
              goodsId:this.goodsId,
              goodsKind:this.$route.query.goodsKind,
              distributionUserId:this.$route.query.distributionUserId,
              backPath:this.$route.path
            }
          })
        }else if(this.detailObj.goodsType==2){
          this.$router.push({
            path:'/learning/task/detail',
            query:{
              id:this.detailObj.referId,
              goodsId:this.goodsId,
              goodsKind:this.$route.query.goodsKind,
              distributionUserId:this.$route.query.distributionUserId,
              backPath:this.$route.path
            }
          })
        }else if(this.detailObj.goodsType==3){
          this.$router.push({
            path:'/humanResting/hrDetails',
            query:{
              id:this.detailObj.referId,//测评id
              refId:this.goodsId,
              refType:2,
              goodsId:this.goodsId,
              distributionUserId:this.$route.query.distributionUserId,
              goodsKind:this.$route.query.goodsKind,
              pathName:this.$route.path
            }
          })
        }
      },
      // 快速学习
      quicklyStudy(){
        localStorage.setItem("faceidentify", 0);
        this.$router.push({
          path: "/scourse",
          query: {
            courseId: this.lastStudyCourseId,
            trainId: this.taskId,
            taskType: this.detailObj.goodsType,
            backType:this.detailObj.goodsType == 1? 2:1  //1返回学习页面培训任务tab，2返回训练营tab
          }
        });
      },
      //获取商品基本信息  
      getGoodsDetail(){
        return this.$api.courseGoodsDetail.findGoodsDetailById(
          {
            goodsId: this.goodsId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              this.detailObj = res.data;
              this.isData = true;
              this.getList();
            }
        })
      },
      /**
       * 分销商品详情
       */
      getDistributionGoodsInfo(){
          const params = {
              id:this.goodsId
          }
          return this.$api.courseGoodsDetail.getDistributionGoodsInfo({params}).then(res => {
              if(res.data){
                this.detailObj = res.data;
                this.isData = true;
                this.getList();
              }
          })
      },
      //目录数据查询
      getList() {
        // console.log(this.detailObj.goodsType)
        if(this.detailObj.goodsType==1){
          //训练营目录
          this.$api.courseGoodsDetail.findTrainListById(
            {
              id: this.detailObj.referId,
            }
          )
          .then(res=>{
              // console.log(res);
              if(res.code===0){
                let tempArr = [];
                  let endData = [];
                  let timeArr = [];
                  let num = [];
                  res.data.menuList.forEach(tiem => {
                      tiem.childItemList = [];
                      num.push(tiem.lastStudyTime);
                      if (tiem.lastStudyTime !== null) {
                          timeArr.push(tiem.lastStudyTime);
                      }
                  });
                  this.timelength = timeArr.sort(function(a, b) {
                      return b > a;
                  });
                  res.data.menuList.forEach(item => {
                      tempArr.push(item.stageName);
                  });
                  let setName = [...new Set(tempArr)]; //.sort();
                  setName.forEach((el, index) => {
                      let datalist = [];
                      res.data.menuList.forEach(items => {
                          if (el === items.stageName) {
                              items.stageName = items.label
                              datalist.push(items);
                          }
                      });
                      endData.push({
                          stageName: el,
                          id: index,
                          type:0,
                          childItemList: datalist,
                          sort:1
                      });
                  });
                  this.catalogList = endData;
                  console.log(this.catalogList)
              }else{
                console.log(res.data.message)
              }
          })
        }
        else if(this.detailObj.goodsType==2){
          //培训任务目录
          this.$api.courseGoodsDetail.findTaskListById(
            {
              id: this.detailObj.referId,
              version:1
            }
          )
          .then(res=>{
              // console.log(res);
              if(res.code==0){
                this.catalogList = res.data.taskItemVoList;
              }
          })
        }

      },
      getQueryString(name) {
          /* eslint-disable */
        var search = window.location.search;
        //alert(search);
        var pattern = new RegExp("[?&]" + name + "\=([^&]+)", "g");
        var matcher = pattern.exec(search);
        var items = null;
        if (null != matcher) {
            try {
                items = decodeURIComponent(decodeURIComponent(matcher[1]));
            } catch (e) {
                try {
                    items = decodeURIComponent(matcher[1]);
                } catch (e) {
                    items = matcher[1];
                }
            }
        }
        return items;
        /* eslint-disable */
      },
      //立即报名
        enroll(){

          if(localStorage.getItem('token')){ 
                  localStorage.setItem('goodsOrderInfo','');
                  var data = {
                    goodsId:this.goodsId,
                    orderSource:2,
                    orderType:this.$route.query.goodsKind == 2 ? 4 : null,
                    agentId: localStorage.getItem('detailAgentId') || '',
                    distributionUserId:this.getQueryString('distributionUserId') || ''
                  }
                  this.$api.courseGoodsDetail.order({data}).then(res=>{
                    if(!res.success) {
                      return
                    }
                    localStorage.setItem('goodsOrderInfo',JSON.stringify(res.data));
                    // if(this.detailObj.isFree==1){  
                    //   this.showT = true;
                    //   this.detailObj.isOrder = 1;
                    //   if(this.isOverType=='oks'){
                    //     setTimeout(()=>{
                    //       this.showT = false
                    //       // if(this.detailObj.goodsType==1){
                    //       //   this.$router.push({
                    //       //     path:'/learning/Details',
                    //       //     query:{
                    //       //       id:this.detailObj.referId,
                    //       //       goodsId:this.goodsId,
                    //       //       backPath:this.$route.path
                    //       //     }
                    //       //   })
                    //       // }else if(this.detailObj.goodsType==2){
                    //       //   this.$router.push({
                    //       //     path:'/learning/Detailstrain',
                    //       //     query:{
                    //       //       id:this.detailObj.referId,
                    //       //       goodsId:this.goodsId,
                    //       //       backPath:this.$route.path
                    //       //     }
                    //       //   })
                    //       // }else if(this.detailObj.goodsType==3){
                    //       //   this.$router.push({
                    //       //     path:'/humanResting/hrDetails',
                    //       //     query:{
                    //       //       id:this.detailObj.referId,//测评id
                    //       //       refId:this.goodsId,
                    //       //       refType:2,
                    //       //       goodsId:this.goodsId,
                    //       //       pathName:this.$route.path
                    //       //     }
                    //       //   })
                    //       // }
                    //     },2000)
                    //   }else{
                    //     setTimeout(()=>{
                    //       this.showT = false
                    //     },2000)
                    //   }
                    // }else{
                    //   // this.$router.push({
                    //   //   path: "/gongxin_payment",
                    //   //   query: {
                    //   //       id: this.goodsId,
                    //   //       agentId: localStorage.getItem('detailAgentId') || '',
                    //   //       iswx: 0
                    //   //   }
                    //   // });
                    //   //
                    //   this.$router.push({
                    //     path: "/gongxin_payment",
                    //     query: {
                    //         orderNo: res.data.orderNo,
                    //         agentId: localStorage.getItem('detailAgentId') || '',
                    //         iswx: 0
                    //     }
                    //   });
                    // }
                    if(res.code==0){
                      if(res.data.placeOrderSuccess && res.data.realPayPrice > 0) {
                        this.$router.push({
                          path: "/gongxin_payment",
                          query: {
                              orderNo: res.data.orderNo,
                              agentId: localStorage.getItem('detailAgentId') || '',
                              iswx: 0
                          }
                        });

                      } else if(res.data.placeOrderSuccess && res.data.realPayPrice === 0){
                        //
                        // this.$message({
                        //   message: '报名成功',
                        //   type: 'success'
                        // })
                        this.showT = true;
                        setTimeout(() => {
                            this.showT = false;
                        }, 2000);
                        this.detailObj.isOrder = 1;
                      } else {
                        if(res.data.url) {
                          window.open(res.data.url, '_self')
                        } else {
                          this.$router.go(-1)
                        }
                      }
                    }

                  })

                
            //})
          }else{
        
              let agentId = localStorage.getItem('detailAgentId') && parseInt(localStorage.getItem('detailAgentId'))
              this.$router.push({
                path: "/login",
                query: {name: "goodsDetail",agentId, ...this.$route.query}
                // query: { 
                //   name: "goodsDetail",
                //   agentId:agentId || '', 
                //   goodsId:parseInt(this.$route.query.goodsId),
                //   goodsKind: this.$route.query.goodsKind,
                //   backName:this.$route.query.backName 
                // }
              });
            }
          },

    },



}

</script>


<style lang="less" scoped>
 @import "../asset/css/courseGoodsDetail.less";
 .detail-foot {
   button {
      width: 690px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      background: #FF594A;
      border-radius: 44px;
      border: 0;
      font-size: 32px;
      margin-top: 10px;
      color: #ffffff;
      &.leftBtn {
          width: 177px;
          height: 80px;
          line-height: 80px;
          background: #fff;
          border-radius: 44px;
          border: 1px solid #DDDDDD;
          margin-right: 18px;
          color: #666666;
      }
      &.rightBtn {
          // width: 495px;
          height: 80px;
          line-height: 80px;
          background: #FF594A;
          border-radius: 44px;
          color: #FFFFFF;
      }
   }
 }
</style>
