<template>
    <div class='opgoodsListCont'>
        <div v-if="showLoading">
            <van-overlay :show="showLoading" />
            <div class="loadinglist">
                <van-loading
                    v-if="showLoading"
                    size="24px"
                    vertical
                >加载中...</van-loading>
            </div>
        </div>
        <div v-else>
            <div style="background-color:#fff;">
                <div
                    class="searchBox"
                    ref="searchBtnRef"
                    v-if="isShowSearchBox"
                    @touchmove.prevent
                >
                    <input
                        type="text"
                        :class="{focusInput: isFocusIpt}"
                        @focus="focusHanle()"
                        v-model="searchKey"
                        @:blur="changeInpt()"
                        placeholder="搜索"
                    />
                    <span class="searchIcon"></span>
                    <span
                        class="escSearch"
                        @click="escSearchIpt()"
                        v-show="isFocusIpt"
                    ></span>
                    <span
                        class="searchBtn"
                        v-show="isFocusIpt && !isShowEsc"
                        @click="searchHandle()"
                    >搜索</span>
                    <span
                        class="searchBtn"
                        v-show="isShowEsc"
                        @click="escSearchIpt()"
                    >取消</span>
                </div>

                <div
                    class="sortTabCont"
                    v-if="isShowClassifyBox"
                >
                    <div class="sortTab" @touchmove.prevent>
                        <div
                            v-for="(item, idx) in  typeList"
                            :key="idx"
                        >
                            <font
                                :class="{active: item.isActive }"
                                @click="changeTabHandle(idx, item)"
                            >{{item.name.substr(0, 10)}}<span :class="{active: item.isActive &&  typeList[currentTabIdx].isShow}"></span></font>
                        </div>
                    </div>
                    <div class="currentSortTab" @touchmove.prevent>
                        <div
                            class="bestNewSortList"
                            v-show="currentTabIdx == 0 && typeList[currentTabIdx].isShow"
                        >
                            <p
                                v-for="(item, idx) in   bestNewSort"
                                :key="idx"
                                @click="bestNewSortListHandle(idx, item)"
                            >
                                <span :class="{active: item.isActive }">{{item.name}}</span>
                            </p>
                        </div>

                        <div
                            class="popularitySortList"
                            v-show="currentTabIdx == 1 && typeList[currentTabIdx].isShow"
                        >
                            <div
                                class="popularitySortCommon popularitySortCommon_first"
                                v-show="firstCourseClassify.length"
                            >
                                <p
                                    v-for="(item, idx) in  firstCourseClassify"
                                    :key="idx"
                                >
                                    <span
                                        v-if="item.showTag"
                                        :class="{'active': item.isActive, 'isActiveBg': item.isActiveBg }"
                                        @click="clickFirstClassifyHandle(idx, item)"
                                    >{{item.name.substr(0, 20)}}</span>
                                </p>
                            </div>

                            <div
                                class="popularitySortCommon popularitySortCommon_second"
                                v-show="secondCourseClassify && secondCourseClassify.length"
                            >
                                <p
                                    v-for="(item, idx) in  secondCourseClassify"
                                    :key="idx"
                                >
                                    <span
                                        v-if="item.showTag"
                                        :class="{active: item.isActive }"
                                        @click="clickSecondClassifyHandle(idx, item)"
                                    >{{item.name.substr(0, 20)}}</span>
                                </p>
                            </div>

                            <div
                                class="popularitySortCommon popularitySortCommon_three"
                                :class="{threeSortBorder:threeCourseClassify.length}"
                                v-show="threeCourseClassify.length"
                            >
                                <p
                                    v-for="(item, idx) in  threeCourseClassify"
                                    :key="idx"
                                >
                                    <span
                                        v-if="item.showTag"
                                        :class="{active: item.isActive }"
                                        @click="clickThreeClassifyHandle(idx, item)"
                                    >{{item.name.substr(0, 20)}}</span>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div
                        class="sortLayer"
                        v-show="(currentTabIdx == 1 || currentTabIdx == 0) && typeList[currentTabIdx].isShow"
                        @click="sortLayerHandle()"
                        @touchmove.prevent
                    ></div>
                </div>
            </div>

            <!-- <div class="searchGoodsListCont" v-if="courseListArray.length"> -->
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="到底了, 没有更多了"
                :immediate-check="false"
                ref="list"
                @load="onLoad"
                v-show="courseListArray.length"
            >
                <div
                    class="searchGoodsList"
                    v-for="(item, idx) in  courseListArray"
                    :key="idx"
                    @click="jumpGoodsDetailHandle(item)"
                >
                    <div class="goodsPoster">
                        <img
                            class="goodsPosterImg"
                            :src="item.pic"
                        />
                        <!-- <p
                            class="goodsSignUpNum"
                            v-if="isShowNumberBuyerBox"
                        >
                            <svg-icon
                                icon-class="course-signup"
                                class-name="account-icon tabbar-icon"
                            >

                            </svg-icon>

                            <span class="sign-up-num">{{item.purchaseNo}}人已报名</span>
                        </p> -->
                    </div>

                    <div class="goodsDetailInfor">
                        <div class="freeInfor">
                            <div class="top_a">
                                <div class="freeInforLeft textCut">{{item.name}}</div>
                                <span class="price" v-if="item.isFree">
                                    免费
                                </span>
                                 <span class="price" v-else>
                                    <span class="fuhao">¥</span>
                                    <span class="num">{{item.price}}</span>
                                </span>
                            </div>

                            <div class="branchName">
                                <span class="name">培训服务商：</span>
                                <span class="txt">{{item.branchName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>

            <!-- </div> -->

            <div
                class="initNoCourseCont"
                v-if="initCourseResult && !courseListArray.length"
            >
                <img src="../../../assets/images/courseGoods/initNoCouse_icon.png" />
                <p class="initNoCourseTips">暂无课程</p>
            </div>

            <div
                class="noCourseCont"
                v-else-if="(isShowSearchBox || isShowClassifyBox) && !paging.total"
            >
                <img src="../../../assets/images/courseGoods/noCourseImg.png" />
                <p class="noCourseTips">没有找到任何课程</p>
            </div>
        </div>

    </div>
</template>

<script>
import { Toast } from "vant";

export default {
  name: "opcourseGoods",
  data() {
    return {
      showLoading: true,
      title: "课程",
      isFocusIpt: false,
      isShowEsc: false,
      searchKey: "",
      initCourseResult: true,

      paging: {
        params: {
          pageNum: 1,
          pageSize: 20
        },
        total: 0,
        totalPages: 0
      },
      typeList: [
        {
          type: 3,
          name: "智能排序",
          isActive: false,
          isShow: false
        },
        {
          type: 2,
          name: "全部课程",
          isActive: false,
          isShow: false
        }
      ],

      bestNewSort: [
        {
          type: 3,
          name: "智能排序",
          isActive: true,
          isShow: false
        },
        {
          type: 1,
          name: "最新排序",
          isActive: false,
          isShow: false
        },
        {
          type: 2,
          name: "人气课程",
          isActive: false,
          isShow: false
        }
      ],

      firstCourseClassify: [],
      secondCourseClassify: [],
      threeCourseClassify: [],

      currentTabIdx: 3,
      currentTabIdxFlag: false,

      currentType: 3,
      categoryCode: "",
      courseListArray: [],

      firstCategoryCode: "",
      secondCategoryCode: "",
      threeCategoryCode: "",

      isShowSearchBox: false,

      isShowClassifyBox: false,

      isShowTitlechBox: false,

      isShowMoreGoodsBox: false,

      isShowNumberBuyerBox: false,

      isShowOneOrManyPageBox: false,

      isShowHowManyListBox: false,

      isShowManyPageManyListBox: false,

      isShowContSourceBox: false,

      loading: false,
      finished: false
    };
  },
  components: {
    // uniLoadMore
  },

  computed: {},

  created() {
    this.configMenuInfor();
  },

  mounted() {},
  destroyed() {},
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },

    getClassifyFindAll() {
      this.$api.openPlatform
        .findstuAll({})
        .then(res => {
          let allName = {
            code: "",
            name: "全部",
            showTag: true
          };
            this.firstCourseClassify = res.data || [];
            if (
              this.$route.query.tabName &&
              decodeURIComponent(this.$route.query.tabName)
            ) {
              let currentTabIdx = 0;
              this.firstCourseClassify.forEach((val, idx) => {
                if (
                  val.name === decodeURIComponent(this.$route.query.tabName)
                ) {
                  console.log(val, idx);
                  currentTabIdx = idx + 1;
                  this.secondCourseClassify = val.children || [];
                }
              });
              this.firstCourseClassify.unshift(allName);
              this.categoryCode = this.firstCourseClassify[currentTabIdx].code;
              this.currentTabIdx = 1;
              this.$set(this.typeList[1], "isActive", true);
              this.$set(
                this.typeList[1],
                "name",
                decodeURIComponent(this.$route.query.tabName)
              );
            } else {
              this.firstCourseClassify.unshift(allName);
              this.$set(
                this.typeList[1],
                "name",
                decodeURIComponent(allName.name)
              );
              this.$set(this.firstCourseClassify[0], "isActive", true);
            }
            
          
        })
        .catch(err => {
          Toast({
            message: err,
            duration: 4000
          });
        });
    },

    focusHanle() {
      this.isFocusIpt = true;
      this.isShowEsc = false;
    },

    escSearchIpt() {
      this.isFocusIpt = false;
      this.isShowEsc = false;
      this.searchKey = "";
      // this.paging.params.pageNum = 1
      this.paging.params.pageNum = 1;
      this.getData();
    },

    searchHandle() {
      this.getData("searchBtn");
      this.isShowEsc = true;
    },

    //tab切换
    changeTabHandle(idx, item) {
      this.currentTabIdx = idx;
      this.currentTabIdxFlag = !this.currentTabIdxFlag;
      this.typeList.forEach((val, index) => {
        if (idx == index) {
          this.$set(val, "isActive", true);
          this.$set(val, "isShow", !val.isShow);
        } else {
          this.$set(val, "isActive", false);
          this.$set(val, "isShow", false);
        }
      });
    },

    //点击最新排序列表
    bestNewSortListHandle(idx, item) {
      this.bestNewSort.forEach((val, index) => {
        if (idx == index) {
          this.$set(val, "isActive", true);
          this.currentType = item.type;
          this.$set(this.typeList[0], "name", item.name);
          this.$set(this.typeList[this.currentTabIdx], "isShow", false);
          this.paging.params.pageNum = 1;
          this.getData();
        } else {
          this.$set(val, "isActive", false);
        }
      });
    },

    async clickFirstClassifyHandle(idx, item) {
      this.secondCourseClassify = [];
      this.threeCourseClassify = [];
      this.firstCategoryCode = item.code;
      this.categoryCode = this.firstCategoryCode;
      this.paging.params.pageNum = 1;

      await this.getData();

      this.firstCourseClassify.forEach((val, index) => {
        if (item.code == val.code) {
          this.$set(val, "isActive", true);
          this.$set(this.typeList[1], "name", item.name);
          this.$set(val, "isActiveBg", true);
          if (val.children) {
            this.secondCourseClassify = val.children;
            this.secondCourseClassify.forEach(val => {
              this.$set(val, "isActive", false);
            });
          } else {
            this.secondCourseClassify = [];
            this.threeCourseClassify = [];
            this.$set(this.typeList[this.currentTabIdx], "isShow", false);
          }
        } else {
          this.$set(val, "isActive", false);
          this.$set(val, "isActiveBg", false);
        }
      });
    },

    async clickSecondClassifyHandle(idx, item) {
      this.threeCourseClassify = [];
      this.secondCategoryCode = item.code;
      this.categoryCode = item.code;
      this.paging.params.pageNum = 1;
      await this.getData();
      this.secondCourseClassify.forEach((val, index) => {
        if (item.code == val.code) {
          this.$set(val, "isActive", true);
          this.$set(this.typeList[1], "name", item.name);
          if (val.children) {
            this.threeCourseClassify = val.children;
            this.threeCourseClassify.forEach(val => {
              this.$set(val, "isActive", false);
            });
          } else {
            this.threeCourseClassify = [];
            this.$set(this.typeList[this.currentTabIdx], "isShow", false);
          }
        } else {
          this.$set(val, "isActive", false);
        }
      });
    },

    async clickThreeClassifyHandle(idx, item) {
      this.threeCategoryCode = item.code;
      this.categoryCode = item.code;
      this.paging.params.pageNum = 1;

      await this.getData();
      this.threeCourseClassify.forEach((val, index) => {
        if (item.code == val.code) {
          this.$set(val, "isActive", true);
          this.$set(this.typeList[1], "name", item.name);
        } else {
          this.$set(val, "isActive", false);
        }
        this.$set(this.typeList[this.currentTabIdx], "isShow", false);
      });
    },

    sortLayerHandle() {
      // this.currentTabIdx = 2;
      // let hideCurrentTab = {
      //     type: 3,
      //     name: '',
      //     isActive: false,
      //     isShow: false,
      //   }
      //   console.log(11111111111)
      // this.typeList.push(hideCurrentTab)
    },

    GetLocationParam(param) {
      var request = {
        QueryString: function(val) {
          var uri = window.location.search;
          var re = new RegExp("" + val + "=([^&?]*)", "ig");
          return uri.match(re)
            ? decodeURI(uri.match(re)[0].substr(val.length + 1))
            : "";
        }
      };
      return request.QueryString(param);
    },
    getData(type) {
      return new Promise((resolve, reject) => {
        if (
          type == "searchBtn" &&
          this.$refs.searchBtnRef &&
          !this.searchKey.trim()
        ) {
          Toast({
            message: "请输入关键字",
            duration: 4000
          });
          return false;
        }

        if (type == "searchBtn") {
          this.initCourseResult = false;
          this.paging.params.pageNum = 1;
        } else {
          this.initCourseResult = true;
        }
        // const reg = new RegExp("(^|&)code=([^&]*)(&|$)");
        // let r = document.location.search.substr(1).match(reg);
        let r = this.GetLocationParam("code");
        const params = {
          pageNum: this.paging.params.pageNum,
          pageSize: this.paging.params.pageSize
        };
        const data = {
          categoryCode: this.categoryCode ? this.categoryCode : r,
          name: this.searchKey,
          type: this.currentType
        };
        this.$api.openPlatform
          .findstuByCondition(params, data)
          .then(res => {
            this.showLoading = false;
            if (res.data && res.data.list) {
              if (type === "reachBottom") {
                this.courseListArray = this.courseListArray.concat(
                  res.data.list
                );
              } else {
                this.courseListArray = res.data.list;
              }
              this.paging.total = res.data.total;
              this.paging.totalPages = res.data.pages;
              this.paging.params.pageNum = res.data.pageNum;
              this.finished = res.data.isLastPage;
              this.loading = false;
              resolve(res);
            } else {
              Toast({
                message: res.message,
                duration: 4000
              });
              reject(res.message);
            }
          })
          .catch(err => {
            reject(err);
            console.log("err", err);
          });
      });
    },
    configMenuInfor() {
      const frontendConfig =
        JSON.parse(localStorage.getItem("configurationArr")) || [];
      frontendConfig.length &&
        frontendConfig.filter(item => {
          switch (item.key) {
            case "is_show_commodity_search": // 是否显示商品搜索
              this.isShowSearchBox = item.value == "true" ? true : false;
              break;
            case "is_show_commodity_sort": // 是否显示商品分类及排序
              this.isShowClassifyBox = item.value == "true" ? true : false;
              break;

            case "is_show_commodity_title": // 是否显示商品列表标题
              this.isShowTitlechBox = item.value == "true" ? true : false;
              break;
            case "is_show_commodity_more_link": // 是否显示更多商品
              this.isShowMoreGoodsBox = item.value == "true" ? true : false;
              break;

            case "is_show_commodity_number_buyer_list_page": // 是否显示XXX人已报名
              this.isShowNumberBuyerBox = item.value == "true" ? true : false;
              break;
            case "is_one_or_many_page": // 单页展示或多页展示
              this.isShowOneOrManyPageBox = item.value == "true" ? true : false;
              break;

            case "how_many_commodity_default": // 默认展示多少条
              this.paging.params.pageSize =
                Number(item.value) > 0
                  ? Number(item.value)
                  : this.paging.params.pageSize;
              break;

            case "how_many_commodity_per_page": // 每页新加载多少条（多页展示情况下）
              //this.paging.params.pageSize = Number(item.value) > 0 ? Number(item.value) : this.paging.params.pageSize
              break;
            case "commodity_source": // 内容来源：全部商品、商品列表中选择某些商品、某些商品分类
              this.isShowContSourceBox = item.value == "true" ? true : false;
              break;

            default:
              break;
          }
        });
      this.getClassifyFindAll();
      this.getData();
    },

    jumpGoodsDetailHandle(item) {
      this.$router.push({
        path: "/openPlatform/courseGoodsDetail",
        query: {
          goodsId: item.goodsId,
          backName: this.$route.path,
          goodsKind: item.goodsKind // 1 商品 2 分销商品
        }
      });
    },

    changeInpt() {
      if (!this.searchKey) {
        this.paging.params.pageNum = 1;
        this.getData();
      }
    },
    onLoad() {
      this.paging.params.pageNum = this.paging.params.pageNum * 1 + 1;
      this.isLoad = true;
      this.getData("reachBottom");
    }
  }
};
</script>


<style scoped lang="less">
@import "../asset/css/courseGoods.less";
</style>
